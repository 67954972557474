import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// import { toJS } from 'mobx';
import { Form } from 'react-final-form';

import ActionBar from '../../../../common/components/actionBar/ActionBar';
import EmbedTypePicker from './EmbedTypePicker';
import SimpleSelectInputField from '../../../../common/components/form-elements/selectInputField/SimpleSelectInputField';
import SimpleCheckboxInputField from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInputField';
import './Embed.css';
import EmbedPreview from './EmbedPreview';
import EmbedInlineOptions from './EmbedInlineOptions';
import EmbedPopupOptions from './EmbedPopupOptions';
import SvgIcon from '../../../../common/components/SvgIcon';
import {
  EMBED_INLINE,
  EMBED_POPUP,
  IFRAME_SOURCE_PLACEHOLDER,
  IFRAME_RESIZER_SCRIPT,
  IFRAME_RESIZER_LOG_SCRIPT,
  IFRAME_RESIZER_SCRIPT_PLACEHOLDER,
  IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER,
  INLINE_EMBED_CODE_TEMPLATE,
  POPUP_EMBED_CODE_TEMPLATE,
  EXCLUDE_HEADER_AND_NAVIGATION_PARAM,
  DYNAMIC_HEIGHT,
  DYNAMIC_HEIGHT_VALUE_PLACEHOLDER,
  DEFAULT_POPUP_BUTTON_LABEL,
  DEFAULT_POPUP_BUTTON_BACKGROUND_COLOR,
  DEFAULT_POPUP_BUTTON_TEXT_COLOR,
  DEFAULT_POPUP_BUTTON_RADIUS,
  DEFAULT_HEIGHT_VALUE,
  POPUP_BUTTON_LABEL_PLACEHOLDER,
  POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER,
  POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER,
  POPUP_BUTTON_RADIUS_PLACEHOLDER,
} from './EmbedEnums';
import ability, { SET, EMBED_START_HOME_SCREEN, EMBED_START_POLLS_SCREEN } from '../../../../common/ability';

const ENGAGE_LANGUAGES = require('../../../../shared/engage/engage-languages')().engageLanguages();

const HOME_SCREEN = ['1', 'Home'];
const POLLS_SCREEN = ['2', 'Surveys'];

/**
 * Main component for the Embed page
 */
function Embed(props) {
  const { PollListStore, ProjectStore, ConfigStore, match } = props;

  const clientEnvironment = ConfigStore.config.clientPath;
  const projectSlug = ProjectStore.project.slug;
  const projectId = match.params.id;

  const BASE_IFRAME_URL = `${clientEnvironment}/${projectSlug}`;

  const [embedType, setEmbedType] = useState(EMBED_POPUP);
  const [pollsList, setPollsList] = useState([]);
  const [codeCopied, setCodeCopied] = useState(false);

  // Common embed type options
  const [startingScreenList, setStartingScreenList] = useState([]);
  const [startingScreen, setStartingScreen] = useState(HOME_SCREEN[0]);
  const [visibleHeaderAndNavigation, setVisibleHeaderAndNavigation] = useState(false);

  // Popup embed type options
  const [buttonLabel, setButtonLabel] = useState(DEFAULT_POPUP_BUTTON_LABEL);
  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_POPUP_BUTTON_BACKGROUND_COLOR);
  const [textColor, setTextColor] = useState(DEFAULT_POPUP_BUTTON_TEXT_COLOR);
  const [borderRadius, setBorderRadius] = useState(DEFAULT_POPUP_BUTTON_RADIUS);
  const [isPreview, setIsPreview] = useState(false);
  const [language, setLanguage] = useState('en');

  // Inline embed type options
  const [dynamicHeight, setDynamicHeight] = useState(true);
  const [dynamicHeightValue, setDynamicHeightValue] = useState(DEFAULT_HEIGHT_VALUE);

  const embedCodeInput = useRef(null);

  useEffect(() => {
    if (PollListStore && projectId) {
      PollListStore.load(projectId).then((res) => {
        const baseList = [];
        if (ability.can(SET, EMBED_START_HOME_SCREEN)) baseList.push(HOME_SCREEN);
        if (ability.can(SET, EMBED_START_POLLS_SCREEN)) baseList.push(POLLS_SCREEN);

        const polls = res || [];
        setPollsList([...polls]);

        const _startingScreenList = [
          ...baseList.concat(
            polls.map((item) => [item._id, `${item.name}${item.status === 'inactive' ? ' (hidden)' : ' (public)'}`]),
          ),
        ];
        setStartingScreenList([..._startingScreenList]);
      });
    }
  }, [PollListStore, projectId]);

  /**
   *
   */
  function getIframeSource() {
    let newIframeUrl = '';

    // setting base url for iframe
    // https://www.givemyview.com/slug[/surveys][/surveyId]
    switch (startingScreen) {
      case HOME_SCREEN[0]:
        newIframeUrl = BASE_IFRAME_URL;
        break;
      case POLLS_SCREEN[0]:
        newIframeUrl = BASE_IFRAME_URL.concat('/', 'polls');
        break;
      default: {
        // start at specific poll
        const selectedPoll = pollsList.find((poll) => poll._id === startingScreen);
        newIframeUrl = BASE_IFRAME_URL.concat('/', 'surveys').concat('/', selectedPoll._id);
      }
    }

    // adding query params for preview, language and header visibility
    const queryParams = [];
    if (!visibleHeaderAndNavigation) queryParams.push(EXCLUDE_HEADER_AND_NAVIGATION_PARAM);
    if (isPreview) queryParams.push('isPreview=true');
    if (language && language !== 'en') queryParams.push(`lang=${language}`);
    if (queryParams.length > 0) newIframeUrl = newIframeUrl.concat('?', queryParams.join('&'));

    return newIframeUrl;
  }

  /**
   *
   */
  function getEmbedCode() {
    let newEmbedCode = '';

    if (embedType === EMBED_POPUP) {
      newEmbedCode = POPUP_EMBED_CODE_TEMPLATE;
      newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_LABEL_PLACEHOLDER, buttonLabel);
      newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER, backgroundColor);
      newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER, textColor);
      newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_RADIUS_PLACEHOLDER, borderRadius);
      newEmbedCode = newEmbedCode.replace(IFRAME_SOURCE_PLACEHOLDER, getIframeSource());
      newEmbedCode.trim();
    } else {
      newEmbedCode = INLINE_EMBED_CODE_TEMPLATE;
      newEmbedCode = newEmbedCode.replace(IFRAME_SOURCE_PLACEHOLDER, getIframeSource());

      if (dynamicHeight) {
        newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_SCRIPT_PLACEHOLDER, IFRAME_RESIZER_SCRIPT);
        newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER, IFRAME_RESIZER_LOG_SCRIPT);
        newEmbedCode = newEmbedCode.replace(DYNAMIC_HEIGHT_VALUE_PLACEHOLDER, DEFAULT_HEIGHT_VALUE);
      } else {
        newEmbedCode = newEmbedCode.replace(DYNAMIC_HEIGHT_VALUE_PLACEHOLDER, dynamicHeightValue);
        newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_SCRIPT_PLACEHOLDER, '');
        newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER, '');
      }

      newEmbedCode.trim();
    }

    return newEmbedCode;
  }

  // Iframe url
  const iframeSource = getIframeSource();
  // Embedding source code
  const embedCode = getEmbedCode();

  // Setting options from child (popup) component
  function setupPopUpEmbed(visibleHeaderAndNav, label, bckgColor, txtColor, borderRad, startingScrn) {
    setButtonLabel(label);
    setBackgroundColor(bckgColor);
    setTextColor(txtColor);
    setBorderRadius(borderRad);
  }

  // Setting options from child (inline) component
  function setupInlineEmbed(visibleHeaderAndNav, isDynamicHeight, dynamicHeightVal, startingScrn) {
    setDynamicHeight(isDynamicHeight);
    setDynamicHeightValue(dynamicHeightVal);
  }

  /**
   *
   */
  function copyHandler(value) {
    embedCodeInput.current.select();
    document.execCommand('copy');
    embedCodeInput.current.selectionStart = embedCodeInput.current.selectionEnd;

    setCodeCopied(true);

    setTimeout(() => {
      setCodeCopied(false);
    }, 2500);
  }

  /**
   *
   */
  function downloadHandler(value) {
    let startScreenName = '';

    if (startingScreen === EMBED_START_HOME_SCREEN[0]) startScreenName = EMBED_START_HOME_SCREEN[1];
    if (startingScreen === EMBED_START_POLLS_SCREEN[0]) startScreenName = EMBED_START_POLLS_SCREEN[1];

    const selectedPoll = pollsList.find((poll) => poll._id === startingScreen);
    if (selectedPoll) startScreenName = selectedPoll.name;

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(embedCode)}`);
    element.setAttribute(
      'download',
      `${ProjectStore.project.projectName}_${startScreenName}_${embedType === EMBED_POPUP ? 'popup' : 'inline'}.html`,
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  /**
   * Prepares [langCode, langTitle] array for the language select input
   */
  function getLanguageOptions() {
    const languages = ProjectStore?.project?.languages;

    if (!languages) return [];

    const languageOptions = languages.map((lang) => {
      const langTitle = ENGAGE_LANGUAGES.find((item) => item.languageCode === lang).languageTitleEN;
      const langOptions = [lang, langTitle];
      return langOptions;
    });

    return languageOptions;
  }
  const languageOptions = getLanguageOptions();

  return (
    <div className='l-main'>
      <ActionBar addNew={null} label='Embed' hasButton={false} />

      <Form
        onSubmit={() => {}}
        render={({ values, form, handleSubmit, submitting, pristine }) => {
          return (
            <form className='c-fields l-form' onSubmit={handleSubmit}>
              <EmbedTypePicker value={embedType} setValue={setEmbedType} />

              <SimpleSelectInputField
                input={{ value: startingScreen, onChange: (event) => setStartingScreen(event.target.value) }}
                choices={startingScreenList}
                label='Starting screen'
                firstOptionEmpty={false}
              />

              {languageOptions.length > 1 && (
                <SimpleSelectInputField
                  input={{
                    value: language,
                    onChange: (event) => setLanguage(event.target.value),
                  }}
                  choices={languageOptions}
                  label='Language'
                  firstOptionEmpty={false}
                />
              )}

              <div className='c-field l-form__item'>
                <label className='c-field__label'>Embed options</label>
              </div>

              <React.Fragment>
                <SimpleCheckboxInputField
                  id='is-preview'
                  input={{
                    checked: isPreview,
                    onChange: (event) => {
                      setIsPreview(event.target.checked);
                    },
                  }}
                  type='checkbox'
                  label='Test mode'
                  isToggle={true}
                  checkedLabel='Test mode enabled (survey answers will not be saved)'
                  uncheckedLabel='Test mode disabled'
                  hideLabel={true}
                />

                <SimpleCheckboxInputField
                  id='visible-header-and-navigation'
                  input={{
                    checked: visibleHeaderAndNavigation,
                    onChange: (event) => {
                      setVisibleHeaderAndNavigation(event.target.checked);
                    },
                  }}
                  type='checkbox'
                  label='Visible header and navigation'
                  isToggle={true}
                  checkedLabel='Visible header and navigation'
                  uncheckedLabel='Visible header and navigation'
                  hideLabel={true}
                />
                {embedType === EMBED_POPUP ? (
                  <EmbedPopupOptions
                    startingScreen={startingScreen}
                    visibleHeaderAndNavigation={visibleHeaderAndNavigation}
                    buttonLabel={buttonLabel}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    borderRadius={borderRadius}
                    onPopupOptionsChange={setupPopUpEmbed}
                  />
                ) : null}

                {embedType === EMBED_INLINE ? (
                  <EmbedInlineOptions
                    startingScreen={startingScreen}
                    visibleHeaderAndNavigation={visibleHeaderAndNavigation}
                    dynamicHeight={dynamicHeight}
                    dynamicHeightValue={dynamicHeightValue}
                    onInlineOptionsChange={setupInlineEmbed}
                  />
                ) : null}

                <EmbedPreview
                  embedType={embedType}
                  iframeSource={iframeSource}
                  buttonLabel={buttonLabel}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                  borderRadius={borderRadius}
                />

                <div className='c-field l-form__item'>
                  <label className='c-field__label' htmlFor='input-embed-code'>
                    Embed code
                  </label>
                  <div className='c-field__group'>
                    <div className='o-textarea o-textarea--code'>
                      <textarea
                        id='input-embed-code'
                        ref={embedCodeInput}
                        value={embedCode}
                        onChange={() => {}}
                        placeholder='Button label'
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className='c-field c-field--action l-form__item'>
                  <div className='c-field__group'>
                    <button
                      className={`o-button ${codeCopied ? 'o-button--success' : 'o-button--primary'}`}
                      type='button'
                      onClick={copyHandler}
                    >
                      {codeCopied && <SvgIcon icon='check' />}
                      <span className='o-label'>{`${codeCopied ? 'Code copied' : 'Copy code'}`}</span>
                    </button>
                    <button className='o-button o-button--alternate' type='button' onClick={downloadHandler}>
                      <span className='o-label'>Download code</span>
                    </button>
                  </div>
                </div>
              </React.Fragment>
            </form>
          );
        }}
      />
    </div>
  );
}

Embed.propTypes = {
  PollListStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  ConfigStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  PollListStore: root.RootStore.pollsListStore,
  ProjectStore: root.RootStore.projectStore,
  ConfigStore: root.RootStore.configStore,
}))(observer(Embed));
