import React from 'react';
import PropTypes from 'prop-types';
import SimpleTextInputField from '../../../../common/components/form-elements/textInputField/SimpleTextInputField';
import SimpleColorPickerField from '../../../../common/components/form-elements/colorPicker/SimpleColorPickerField';
import SimpleCheckboxInputField from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInputField';

function EmbedPopupOptions(props) {
  const {
    startingScreen,
    visibleHeaderAndNavigation,
    buttonLabel,
    backgroundColor,
    textColor,
    borderRadius,
    onPopupOptionsChange,
  } = props;
  return (
    <React.Fragment>
      <SimpleTextInputField
        id='input-button-label'
        input={{
          value: buttonLabel,
          onChange: (event) =>
            onPopupOptionsChange(
              visibleHeaderAndNavigation,
              event.target.value,
              backgroundColor,
              textColor,
              borderRadius,
              startingScreen,
            ),
        }}
        placeholder='Button label'
        label='Button label'
        fieldDescription=''
        isRequired={false}
        fieldClass='l-form__item--xs'
        meta={{}}
      />

      <SimpleColorPickerField
        id='background-color-picker'
        input={{
          value: backgroundColor,
          onChange: (event) =>
            onPopupOptionsChange(
              visibleHeaderAndNavigation,
              buttonLabel,
              event.target.value,
              textColor,
              borderRadius,
              startingScreen,
            ),
        }}
        placeholder='Hex color'
        label='Background color'
        fieldDescription=''
        isRequired={false}
        fieldClass='l-form__item--xs'
      />

      <SimpleColorPickerField
        id='text-color-picker'
        input={{
          value: textColor,
          onChange: (event) =>
            onPopupOptionsChange(
              visibleHeaderAndNavigation,
              buttonLabel,
              backgroundColor,
              event.target.value,
              borderRadius,
              startingScreen,
            ),
        }}
        placeholder='Hex color'
        label='Text color'
        fieldDescription=''
        isRequired={false}
        fieldClass='l-form__item--xs'
      />

      <SimpleTextInputField
        id='input-border-radius'
        input={{
          value: borderRadius,
          onChange: (event) =>
            onPopupOptionsChange(
              visibleHeaderAndNavigation,
              buttonLabel,
              backgroundColor,
              textColor,
              event.target.value,
              startingScreen,
            ),
        }}
        placeholder='Button label'
        label='Border radius'
        fieldDescription=''
        isRequired={false}
        fieldClass='l-form__item--xs'
        customType='number'
        meta={{}}
      />
    </React.Fragment>
  );
}

EmbedPopupOptions.propTypes = {
  startingScreen: PropTypes.string.isRequired,
  visibleHeaderAndNavigation: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  onPopupOptionsChange: PropTypes.func.isRequired,
};

export default EmbedPopupOptions;
